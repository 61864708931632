import { Box, Text } from '@shopify/polaris';
import React from 'react';
import earnAvenues from '../../assets/images/earn-avenues.svg';
import redeemPlan from '../../assets/images/redeem-plan.svg';
import twitterIcon from '../../assets/images/x.svg';
import InstaIcon from '../../assets/images/insta.svg';
import FcaeIcon from '../../assets/images/face.svg';
import { useSelector } from 'react-redux';

const DefaultPreview = ({ formik }) => {
    const { theme_colors, panel_homepage_member, panel_homepage_visitor, launcher_general, launcher_desktop, panel_program_card_settings } = formik.values;
    const panel_id = useSelector(state => state?.data?.panel_id);

    const customerNameTitle =  panel_homepage_member?.activity_banner_title && panel_homepage_member?.activity_banner_title.includes('{firstname}') ? panel_homepage_member?.activity_banner_title.replaceAll('{firstname}', 'John') :  panel_homepage_member?.activity_banner_title;

    const shop_info = useSelector((state) => state.data.store_data?.shop_data);
    const points_branding = shop_info?.points_branding;

    const PointsTitle = panel_program_card_settings?.points_desc && panel_program_card_settings?.points_desc.includes('{points}') ? panel_program_card_settings?.points_desc.replaceAll('{points}', points_branding?.points_currency_name) : panel_program_card_settings?.points_desc; 

    return (
        <>
            {theme_colors && <style>
                .preview-card-sec .preview-text {'{'}
                background-color: {`${theme_colors?.banner_color}!important`};
                {'}'}
                .preview-card-sec .preview-membership-container{'{'}
                background: {`${theme_colors?.background_color}!important`};
                {'}'}
                .preview-membership-container::before{'{'}
                background-image: url{`("data:image/svg+xml,%3Csvg width='386' height='415' viewBox='0 0 386 415' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 4s115.695 50.88 193 50.88S386 4 386 4v1122H0z' fill='%23${theme_colors?.background_color?.split('#')[1]}'/%3E%3C/svg%3E%0A")`}
                {'}'}
                .preview-btn-secondary {'{'}
                border-color: {`${theme_colors?.default_buttons_border_color}!important`};
                color: {`${theme_colors?.default_buttons_font}!important`};
                {'}'}
                .preview-btn-secondary .btn-secondary-text {'{'}
                color: {`${theme_colors?.default_buttons_font}!important`};
                {'}'}
                .preview-ly-pro-btn button .preview-ly-pro-arrow svg path {'{'}
                fill: {`${theme_colors?.default_buttons_font}!important`};
                {'}'}
                .preview-card-sec .preview-btn {'{'}
                background-color: {`${theme_colors?.primary_buttons_color}!important`};
                color: {`${theme_colors?.primary_buttons_font}!important`};
                {'}'}
                .preview-fab-icon {'{'}
                background-color: {`${launcher_general?.launcher_color}!important`};
                color: {`${launcher_general?.launcher_font_color}!important`};
                {'}'}
                .preview-fab-icon svg path {'{'}
                fill: {`${launcher_general?.launcher_font_color}!important`};
                {'}'}
                .preview-fab-icon-container {'{'}
                justify-content: {`${launcher_desktop?.desktop_placement === 'Left' ? 'flex-start' : 'flex-end'}!important`};
                {'}'}
            </style>}
            <Box padding={400}>
                <div className='preview-card-sec'>
                    <div className='preview-card-container'>
                        <div className='preview-text' style={{ color: `${theme_colors?.banner_font}` }}>
                            <Text as="p" variant="bodyMd" fontWeight='bold'>
                                {panel_id === 'visitor' ? panel_homepage_visitor?.header_text_title : panel_homepage_member?.header_text_title || 'Welcome to our store'}
                            </Text>
                        </div>
                        <div className='preview-membership-container'>
                            <div className="preview-mem-card-con login-card-default-preview">
                                {/* login card */}
                                {panel_id === 'member' ? <>
                                    <div className='preview-half-card'>
                                        <div className='preview-mem-sec'>
                                        <p>👋 {customerNameTitle || 'Hello John'}</p>
                                        </div>
                                        <div className='login-in-card'>
                                            <p className='points-links'>
                                                <a href="#">
                                                Your points
                                                    <div className='login-arrow-right'>
                                                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.699902 0.740094C0.632919 0.812317 0.58082 0.897027 0.546579 0.989387C0.512339 1.08175 0.496627 1.17995 0.500341 1.27838C0.504056 1.37681 0.527123 1.47355 0.568228 1.56307C0.609332 1.65258 0.667667 1.73312 0.739901 1.80009L3.6479 4.50009L0.739901 7.20009C0.594032 7.33535 0.507869 7.52302 0.500368 7.72181C0.492866 7.9206 0.564641 8.11423 0.699901 8.26009C0.835162 8.40596 1.02283 8.49213 1.22162 8.49963C1.42041 8.50713 1.61403 8.43536 1.7599 8.30009L5.2599 5.05009C5.33563 4.97989 5.39604 4.8948 5.43736 4.80016C5.47867 4.70551 5.5 4.60336 5.5 4.50009C5.5 4.39683 5.47867 4.29467 5.43736 4.20003C5.39604 4.10539 5.33563 4.0203 5.2599 3.95009L1.7599 0.700094C1.68768 0.633112 1.60297 0.581013 1.51061 0.546772C1.41825 0.512531 1.32005 0.496819 1.22162 0.500533C1.12318 0.504248 1.02645 0.527316 0.936931 0.56842C0.847414 0.609524 0.766871 0.667859 0.699902 0.740094Z" fill="#202223" />
                                                        </svg>
                                                    </div>
                                                </a>
                                                <div className='login-points-number'>
                                                2000
                                                </div>
                                            </p>
                                            <div className='login-in-btn-group'>
                                                <div className="button-badge">
                                                    <button type='button' className='preview-btn'>Avail Coupons</button>
                                                    <div className="badge-btn">
                                                    3
                                                    </div>
                                                </div>
                                                <div className="button-badge">
                                                    <button type='button' className='preview-btn-secondary'>Use Coupon</button>
                                                    <div className="badge-btn">
                                                    3
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='preview-sign-in'>
                                                <div className='login-card-social'>
                                                    <div className='login-card-social-link'>
                                                        <div className='login-icons-link'>
                                                            <img src={twitterIcon} />
                                                        </div>
                                                        <div className='login-icons-link'>
                                                            <img src={InstaIcon} />
                                                        </div>
                                                        <div className='login-icons-link'>
                                                            <img src={FcaeIcon} />
                                                        </div>
                                                    </div>
                                                    <div className='social-content'>
                                                        <p className='social-link-content' style={{ color: `${theme_colors?.links_color}` }} >{` ${panel_homepage_member?.social_activity}` || ' Complete social activities & earn points '}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='preview-half-card'>
                                        <div className='preview-mem-sec'>
                                           <p> {panel_homepage_member?.birthday_title || 'Birthday'}</p>
                                        </div>
                                        <div className='preview-mem-join-now birthdate-container'>
                                            <div className='birthdate-head-content'>
                                                <p>{panel_homepage_member?.birthday_desc || 'We want to celebrate your birthday! '}</p>
                                            </div>
                                            <div className='birthdate-content-input'>
                                                <div className='birthdate-input-flex'>
                                                    <div className="birthdate-md-box">
                                                        <div className="birthdate-flex-box">
                                                            <p className='m-0'>Month</p>
                                                            <div className="birthdate-md-input">
                                                                <input type="text" className="birthdate-month" placeholder='M' />
                                                                <input type="text" className="birthdate-month" placeholder='M' />
                                                            </div>
                                                        </div>
                                                        <div className="birthdate-flex-box">
                                                            <p className='m-0'>Day</p>
                                                            <div className="birthdate-md-input">
                                                                <input type="text" className="birthdate-day" placeholder='D' />
                                                                <input type="text" className="birthdate-day" placeholder='D' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-1'>
                                                <button type='button' className='preview-btn'>Save date</button>
                                            </div>
                                        </div>
                                    </div>
                                </> : <>
                                    {/* End login card */}
                                    <div className='preview-half-card'>
                                        <div className='preview-mem-sec'>
                                           <p> {panel_homepage_visitor?.account_creation_title || 'Membership Card'}</p>
                                        </div>
                                        <div className='preview-mem-join-now'>
                                            <p>{panel_homepage_visitor?.account_creation_desc || 'Explore range of opportunities to unlock enticing perks, ensuring access to exclusive rewards.'}</p>
                                            <button type='button' className='preview-btn'>
                                                {panel_homepage_visitor?.account_creation_button || 'Join now'}
                                            </button>
                                            <div className='preview-sign-in'>
                                                <p> <a href='#' style={{ color: `${theme_colors?.links_color}` }}>{panel_homepage_visitor?.account_creation_signin || 'Already have an account? Sign in'} </a></p>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                <div className='preview-half-card'>
                                    <div className='preview-mem-sec'>
                                        <p>{panel_program_card_settings?.points_title || 'Loyalty Program'}</p>
                                    </div>
                                    <div className='preview-mem-join-now'>
                                        <p>{PointsTitle || 'Accrue points from a variety of actions and trade them for appealing rewards.'}</p>
                                        <div className='preview-loyal-card'>
                                            <div className="preview-ly-pro-container">
                                                <div className='preview-ly-pro-box'>
                                                    <div className="preview-ly-pro-icon earn-evenues">
                                                        <img src={panel_program_card_settings?.earn_points_icon_type === 0 ? earnAvenues : (panel_program_card_settings?.earn_points_icon_custom)  || `https://${panel_program_card_settings?.earn_points_icon}` || earnAvenues} alt='earn avenues' />
                                                    </div>
                                                    <div className="preview-ly-pro-btn">
                                                        <button type='button' className='preview-btn-secondary btn-secondary'>
                                                            <p className='btn-secondary-text'>Earn avenues</p>
                                                            <div className='preview-ly-pro-arrow'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fillRule="evenodd" d="M3.5 10a.75.75 0 0 1 .75-.75h9.69l-2.72-2.72a.75.75 0 1 1 1.06-1.06l4 4a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06-1.06l2.72-2.72h-9.69a.75.75 0 0 1-.75-.75Z" /></svg>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='preview-ly-pro-box'>
                                                    <div className="preview-ly-pro-icon redeem-plans">
                                                        <img src={panel_program_card_settings?.redeem_points_icon_type === 0 ? redeemPlan : (panel_program_card_settings?.redeem_points_icon_custom) || `https://${panel_program_card_settings?.redeem_points_icon}` || redeemPlan} alt='earn avenues' />
                                                    </div>
                                                    <div className="preview-ly-pro-btn">
                                                        <button type='button' className='preview-btn-secondary btn-secondary'>
                                                            <p className='btn-secondary-text'>Redeem plans</p>
                                                            <div className='preview-ly-pro-arrow'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fillRule="evenodd" d="M3.5 10a.75.75 0 0 1 .75-.75h9.69l-2.72-2.72a.75.75 0 1 1 1.06-1.06l4 4a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06-1.06l2.72-2.72h-9.69a.75.75 0 0 1-.75-.75Z" /></svg>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='preview-fab-icon-container'>
                        <div className="preview-fab-icon">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.16 19.539a.975.975 0 1 0 1.379-1.378L14.378 13l5.16-5.161a.975.975 0 0 0-1.377-1.378L13 11.62 7.839 6.46A.975.975 0 0 0 6.46 7.839l5.16 5.16-5.16 5.162a.975.975 0 1 0 1.378 1.378l5.16-5.161z" fill="#fff"/></svg>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
};

export default DefaultPreview;