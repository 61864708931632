import React from 'react';
import { TextField } from '@shopify/polaris';

const Input = ({
    label,
    value,
    handleChange,
    handleBlur,
    touched, maxLength, handleFocus,
    helper, pattern, type, prefix, disabled,
    suffix, placeholder, name, min, max, error, showCharacterCount = false,
    borderless = false, connectedRight, required = false }) => (

    <TextField
        label={label}
        value={value}
        autoComplete="off"
        onChange={(value) => handleChange(name, value)}
        onBlur={() => (handleBlur ? handleBlur(name) : {})}
        onFocus={() => (handleFocus ? handleFocus(name) : {})}
        helpText={helper}
        type={type}
        pattern={pattern}
        prefix={prefix}
        suffix={suffix}
        placeholder={placeholder}
        name={name}
        min={min}
        max={max}
        maxLength={maxLength}
        error={touched && error}
        requiredIndicator={required}
        connectedRight={connectedRight}
        showCharacterCount={showCharacterCount}
        borderless={borderless}
        disabled={disabled}
    />

);

export default Input;
