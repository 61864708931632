import React from 'react';
import { ContextualSaveBar } from '@shopify/polaris';

function Savebar({ savbtn, onClick, disabled, loader, savemsg, onDiscard}) {
    const msg = savemsg ? savemsg : 'Unsaved changes';

    return (
        <>
            {<ContextualSaveBar
                alignContentFlush
                message={msg}
                saveAction={{
                    content: `${savbtn}`,
                    onAction: () => onClick(),
                    loading: loader,
                    disabled
                }}
                discardAction={{
                    content: 'Discard',
                    onAction: () => onDiscard()
                }}
            />}
        </>
    );
}

export default Savebar;