import { Box } from '@shopify/polaris';
import React from 'react';
import MobilePreviewImg from '../../assets/images/mobilepreview.png';
import { getIcon } from '../../helper/commonFunction';

const MobilePreview = ({ formik, brandingData }) => {
    const { launcher_general, launcher_mobile } = formik.values;

    return (<>
        {launcher_general && <style>
            .desktop-preview-container .fab-icon-box .fab-icon-sec {'{'}
            background-color: {`${launcher_general?.launcher_color}!important`};
            color: {`${launcher_general?.launcher_font_color}!important`}
            {'}'}
                
            .desktop-preview-container .fab-icon-box .fab-icon-sec .fab-icon-img svg path{'{'}
            fill: {`${launcher_general?.launcher_font_color}!important`}
            {'}'}
            .desktop-preview-container .fab-icon-box {'{'}
                justify-content: {`${launcher_mobile?.mobile_placement === 'Left' ? 'flex-start' : 'flex-end'}!important`};
            {'}'}
            .desktop-preview-container .fab-icon-box .fab-icon-innerbox {'{'}
                padding-bottom: {launcher_mobile?.mobile_bottom_spacing > 10 && launcher_mobile?.mobile_bottom_spacing < 367 ? `${launcher_mobile?.mobile_bottom_spacing}px` : `${launcher_mobile?.mobile_bottom_spacing > 367 ? '367px' : '10px' }`};
                padding-left: {launcher_mobile?.mobile_placement === 'Left' && launcher_mobile?.mobile_side_spacing > 10 && launcher_mobile?.mobile_side_spacing < 148 ? `${launcher_mobile?.mobile_side_spacing}px` : `${launcher_mobile?.mobile_side_spacing > 148 ? '148px' : '10px' }`};
                padding-right: {launcher_mobile?.mobile_placement === 'Right' && launcher_mobile?.mobile_side_spacing > 10 && launcher_mobile?.mobile_side_spacing < 147 ? `${launcher_mobile?.mobile_side_spacing}px` : `${launcher_mobile?.mobile_side_spacing > 147 ? '147px' : '10px' }`}
            {'}'}
        </style>}
        <Box padding={400}>
            <div className='desktop-preview-container mobile-img-container'>
                <div className='desktop-preview-box'>
                    <div className='desktop-pre-img'>
                        <img src={MobilePreviewImg} />
                    </div>
                    <div className={`fab-icon-box fab-icon-box-mobile ${launcher_mobile?.mobile_placement === 'Right' ? 'launcher-mobile-right-fabicon' : 'launcher-mobile-left-fabicon'}`}>
                        <div className='fab-icon-innerbox'>
                            <div className='fab-icon-sec'>
                                <div className='fab-icon-img'>
                                    {launcher_mobile?.visibility === 'icon' ? <>
                                        {(launcher_general.icon_type === 0 || (brandingData?.launcher_general.icon_type === 0 && !launcher_general?.icon_custom)) ? getIcon(launcher_general?.icon_value) : <img width={'30px'} height={'30px'} src={launcher_general?.icon_custom.includes('data:image') ? launcher_general?.icon_custom : `https://${launcher_general?.icon}`}/>} 
                                    </> : <>
                                        <div className='fab-icon-content'>
                                            <p>{launcher_general?.text}</p>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    </>);
};

export default MobilePreview;