import { Box, Button, Collapsible, Divider, DropZone, FormLayout, HorizontalGrid, HorizontalStack, InlineError, Label, LegacyStack, Text, TextField, Thumbnail, Tooltip, VerticalStack } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import Desktop from './Desktop';
import ArrowDownIcon from '../../../assets/images/arrow-down.svg';
import ArrowUpIcon from '../../../assets/images/arrow-up.svg';
import DesktopIcon from '../../../assets/images/desktop.svg';
import MobileIcon from '../../../assets/images/mobile.svg';
import Mobile from './Mobile';
import DeleteModal from '../../../common-components/pop_up_modal/DeleteModal';
import Input from '../../../common-components/Input';
import CheckList from '../../../common-components/ChoiceList';
import DropZoneFile from '../../../common-components/DropZoneFile';
import { useDispatch, useSelector } from 'react-redux';
import { handleLauncherId } from '../../../redux/slice/DataSlice';
import { DeleteMinor } from '@shopify/polaris-icons';
import { encodeImageFileAsURL } from '../../../helper/commonFunction';

const Launcher = ({ formik, brandingData }) => {
    const dispatch = useDispatch();
    const launcher_general = formik.values?.launcher_general;
    const launcher_id = useSelector(state => state?.data?.launcher_id);
    const [loading, setLoading] = useState(false);
    const [launcherID, setLauncherID] = useState(launcher_id);
    const [visible, setVisible] = useState(false);
    const [defaultColorSelected, setDefaultColorSelect] = useState(false);
    const [customIcon, setCustomIcon] = useState(brandingData?.launcher_general?.icon_type ? brandingData?.launcher_general?.icon_custom ? brandingData?.launcher_general?.icon_custom : formik.values?.launcher_general?.icon_custom ? formik.values?.launcher_general?.icon_custom : '' : '');
    const [iconCustomLauncher, setCustomIconLauncher] = useState('');
    const defaultIcons = launcher_general?.default_icons;
    const primary_color = '#332105';
    const secondary_color = '#EBE9E6';
    const defaultColorData = {
        launcher_color: '#332105',
        launcher_font_color: '#EBE9E6'
    };

    function handleToggle(id = false) {
        try {
            if (id && launcherID !== id) {
                setLauncherID(id);
                dispatch(handleLauncherId(id));
            } else {
                setLauncherID(false);
                dispatch(handleLauncherId(false));
            }
        } catch (error) {
            console.log('error------------------', error);
        }
    }

    function handleThemeSelection(e) {
        const name = e.target.name;
        const value = e.target.value;
        formik.setFieldValue(name, value);
    }

    const resetAllColor = () => {
        for (const key in defaultColorData) {
            if (Object.hasOwnProperty.call(defaultColorData, key)) {
                const colorValue = defaultColorData[key];
                formik.setFieldValue(`launcher_general.${key}`, colorValue);
            }
        }
        setDefaultColorSelect(true);
        setLoading(false);
        setVisible(false);
    };
    useEffect(() => {
        if (brandingData?.launcher_general?.icon_type) {
            formik.setFieldValue('launcher_general.icon_custom', brandingData?.launcher_general?.icon_value);
            setCustomIcon(brandingData?.launcher_general?.icon);
            setCustomIconLauncher(brandingData?.launcher_general?.icon_value);
        }
    }, [brandingData?.launcher_general]);

    useEffect(() => {
        if (formik.values.launcher_general?.icon_type && formik.values.launcher_general?.icon_custom) {
            formik.setFieldValue('launcher_general.icon_custom', formik.values.launcher_general?.icon_custom);
            setCustomIcon(formik.values.launcher_general?.icon_custom);
        }
    }, [formik.values.launcher_general?.icon_custom]);

    useEffect(() => {
        try {
            if (formik.values?.launcher_general?.icon_type) {
                formik.setFieldValue('launcher_general.icon_custom', formik.values?.launcher_general?.icon_custom);
                formik.setFieldValue('launcher_general.icon_value', brandingData?.launcher_general?.icon_value);
                setCustomIcon(formik.values?.launcher_general?.icon_custom || '');
                setCustomIconLauncher(brandingData?.launcher_general?.icon_type ? brandingData?.launcher_general?.icon_value : formik.values?.launcher_general?.icon_custom);
            } else if (brandingData?.launcher_general?.icon_type) {
                const icon = Object.values(defaultIcons)[0];
                formik.setFieldValue('launcher_general.icon_value', icon);
            } else if (brandingData?.launcher_general?.icon_type === 0) {
                formik.setFieldValue('launcher_general.icon_value', brandingData?.launcher_general?.icon_value);
            }
        } catch (error) {
            console.log('error----------------', error);
        }
    }, [formik.values?.launcher_general?.icon_type, brandingData?.launcher_general?.icon_type]);
    const handleImportFileUpload = (value) => {
        setCustomIconLauncher(value);
        formik.setFieldValue('launcher_general.icon_custom', value);
    };

    useEffect(() => {
        if (!formik.dirty && iconCustomLauncher) {
            setCustomIconLauncher('');
        }
    }, [formik.dirty]);

    const handleUpload = async (files, accepted) => {
        try {
            setCustomIcon(files[0]);
            const getBase64 = await encodeImageFileAsURL(files[0]);
            formik.setFieldValue('launcher_general.icon_custom', getBase64);
        } catch (error) {
            console.log('error----------------- ', error);
        }
    };

    const uploadedFile = (customIcon || formik.values.launcher_general?.icon_value) && (
        <>
            <Box padding={200} borderColor='border' borderRadius='150' borderWidth='1'>
                <HorizontalStack blockAlign='center' align='space-between'>
                    <HorizontalStack gap={200} blockAlign='center' >
                        <div className={`iwlauncherIcon`}>
                            <Thumbnail
                                size="medium"
                                alt={customIcon?.name || 'custom icon'}
                                source={
                                    customIcon?.name ? window.URL.createObjectURL(customIcon) : customIcon?.includes('data:image/') ? customIcon : formik.values?.launcher_general?.icon_custom ? `http://${customIcon}` : `http://${formik.values.launcher_general.icon}`
                                }
                            />
                        </div>
                        <Text>{customIcon?.name ? customIcon?.name : customIcon?.includes('data:image/') ? 'custom icon' : formik.values.launcher_general.icon_value || 'custom icon'}</Text>
                        <Button icon={DeleteMinor} plain pressed onClick={() => { setCustomIcon(''); formik.setFieldValue('launcher_general.icon_custom', ''); formik.setFieldValue('launcher_general.icon', ''); }}></Button>
                    </HorizontalStack>
                </HorizontalStack>
            </Box>
        </>
    );

    // const renderChildren = useCallback(
    //     (isSelected) => isSelected && (
    //         <Box>
    //             {formik.values.icon_type ? <HorizontalGrid columns={2} alignItems='center' gap={100}>
    //                 {customIcon ? <div>
    //                     {uploadedFile}
    //                 </div> : <>
    //                     <DropZone allowMultiple={false} onDrop={handleUpload} type='image'>
    //                         <DropZone.FileUpload actionTitle='Add icon' />
    //                     </DropZone>
    //                 </>}
    //                 {!customIcon ? <Box>
    //                     <Text color='subdued' variant='bodySm'>Size 90px by 90px </Text>
    //                     <Text color='subdued' variant='bodySm'>Accepts .svg and .png</Text>
    //                 </Box> : null}
    //             </HorizontalGrid> : <Thumbnail size='small' source={`http://${formik.values.default_image || formik.values.image}`} />}
    //             {formik.errors.icon_custom && formik.touched.icon_custom ? <InlineError message={formik.errors.icon_custom} /> : null}
    //         </Box>
    //     ),
    //     [formik.values?.launcher_general?.icon_type, defaultIcons, formik.values?.launcher_general?.icon_value, formik.errors?.launcher_general?.icon_custom, formik.touched?.launcher_general?.icon_custom, iconCustomLauncher]
    // );

    const renderChildren = useCallback(
        (isSelected) => isSelected && (
            <Box>
                {formik.values?.launcher_general?.icon_type ?
                    // eslint-disable-next-line multiline-ternary
                    <>
                        <div className='custom-icon-container'>
                            {customIcon ? <div>
                                {/* <Label>{customIcon}</Label> */}
                                {uploadedFile}
                            </div> : <>
                                <DropZone
                                    // accept='image/svg, image/png, image/png, image/jpeg'
                                    allowMultiple={false}
                                    onDrop={handleUpload}
                                    type='image'>
                                    <DropZone.FileUpload actionTitle='Add icon' />
                                </DropZone>
                            </>}
                        </div>
                    </>
                    : <><div className='default-icon-container'>
                        <div className='default-icon-box'>
                            {Object.values(defaultIcons).map((icon, index) => {
                                return (
                                    <div className={`default-icons ${formik.values?.launcher_general?.icon_value === icon ? 'lnr_selected' : ''}`} key={index} onClick={() => formik.setFieldValue('launcher_general.icon_value', icon)}>
                                        <img src={`https://devrelo.identixweb.com/images/${icon}`} alt='gift' name='launcher_general.icon_value' />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    </>
                }
                {formik.errors?.launcher_general?.icon_custom && formik.touched?.launcher_general?.icon_custom ? <InlineError message={formik.errors?.launcher_general?.icon_custom} /> : null}
            </Box>
        ),
        [formik.values?.launcher_general?.icon_type, defaultIcons, formik.values?.launcher_general?.icon_value, formik.errors?.launcher_general?.icon_custom, formik.touched?.launcher_general?.icon_custom, iconCustomLauncher, customIcon]
    );

    return (
        <>
            <Divider />
            <Box padding={300} >
                <LegacyStack alignment="center" distribution='fill'>
                    <LegacyStack.Item>
                        <Text as="h3" variant="headingMd">
                            Launcher color
                        </Text>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                        <Tooltip content='Revert to default'>
                            <div className='color-picker theme-color-default' onClick={() => setVisible(!visible)} style={{ borderColor: defaultColorSelected ? '#2C6ECB' : '' }}>
                                <div style={{ backgroundColor: primary_color }} className='btn-revert-color'></div>
                                <div style={{ backgroundColor: secondary_color }} className='btn-revert-color'></div>
                                {/* <Box><input type='color' name='theme_primary_color' value={primary_color} readOnly/></Box> */}
                                {/* <Box><input type='color' name='theme_secondary_color' value={secondary_color} readOnly/></Box> */}
                            </div>
                        </Tooltip>
                        <DeleteModal
                            deletePopUpActive={visible}
                            handleDeletePopUp={() => setVisible(false)}
                            popUpContent='Are you sure to reset it with default colors?'
                            primaryAction={{ content: 'Revert', loading: loading === 'revert', onAction: () => { setLoading('revert'); setTimeout(() => resetAllColor(), 500); }, destructive: true }}
                            secondaryActions={[{ content: 'Cancel', onAction: () => setVisible(false) }]}
                            popUpTitle={'Revert to default?'}
                        />
                    </LegacyStack.Item>
                </LegacyStack>
                <Box paddingBlockStart={200}>
                    <LegacyStack alignment="center" distribution='fill'>
                        <LegacyStack.Item>
                            <Input
                                label='Color'
                                type='value'
                                value={(formik.values?.launcher_general?.launcher_color.trimStart())}
                                handleChange={formik.setFieldValue}
                                name='launcher_general.launcher_color'
                                handleBlur={formik.setFieldTouched}
                                error={formik.errors?.launcher_general?.launcher_color}
                                touched={formik.touched?.launcher_general?.launcher_color}
                                connectedRight={
                                    <Box><input type='color' name='launcher_general.launcher_color' value={formik.values?.launcher_general?.launcher_color} onChange={(e) => handleThemeSelection(e)} /></Box>
                                }
                            />
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                            <Input
                                label='Font'
                                type='value'
                                value={(formik.values?.launcher_general?.launcher_font_color.trimStart())}
                                handleChange={formik.setFieldValue}
                                name='launcher_general.launcher_font_color'
                                handleBlur={formik.setFieldTouched}
                                error={formik.errors?.launcher_general?.launcher_font_color}
                                touched={formik.touched?.launcher_general?.launcher_font_color}
                                connectedRight={
                                    <Box><input type='color' name='launcher_general.launcher_font_color' value={formik.values?.launcher_general?.launcher_font_color} onChange={(e) => handleThemeSelection(e)} /></Box>
                                }
                            />
                        </LegacyStack.Item>
                    </LegacyStack>
                </Box>
            </Box>
            <Divider />
            <Box padding={300}>
                <VerticalStack gap={200}>
                    <Text as="h3" variant="headingMd">
                        Set text & icon
                    </Text>
                    <Input
                        label='Text'
                        type='value'
                        placeholder={'Reward'}
                        value={formik.values?.launcher_general?.text.trimStart()}
                        handleChange={formik.setFieldValue}
                        name='launcher_general.text'
                        handleBlur={formik.setFieldTouched}
                        error={formik.errors?.text}
                        touched={formik.touched?.text}
                        autoComplete="off"
                    />
                    <Box>
                        <LegacyStack vertical spacing='none'>
                            <CheckList
                                optionsData={[
                                    { label: 'Default icon', value: 0, renderChildren },
                                    { label: 'Upload custom icon', value: 1, renderChildren }
                                ]}
                                selected={[formik.values?.launcher_general?.icon_type]}
                                name='launcher_general.icon_type'
                                handleChange={(name, selected) => formik.setFieldValue(name, selected[0])}
                            />
                        </LegacyStack>
                    </Box>
                </VerticalStack>
            </Box>
            <Divider />
            <Box padding={300} >
                <button
                    className={'collapse-button-container'}
                    onClick={() => handleToggle('desktop')}
                    aria-expanded={launcherID === 'desktop'}
                    aria-controls="basic-collapsible">
                    <LegacyStack alignment="center">
                        <LegacyStack.Item fill>
                            <div className='branding-toogle-container'>
                                <div className='branding-color-title'>
                                    <img
                                        src={DesktopIcon}
                                        alt="color icon"
                                    />
                                </div>
                                <Text as="h3" variant="headingMd" fontWeight='medium'>
                                    Desktop
                                </Text>
                            </div>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                            {launcherID === 'desktop' ? <img src={ArrowUpIcon} alt="arrow down icon" /> : <img src={ArrowDownIcon} alt="arrow down icon" />}
                        </LegacyStack.Item>
                    </LegacyStack>
                </button>
            </Box>
            <Collapsible
                open={launcherID === 'desktop'}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                <Desktop formik={formik} />
            </Collapsible>
            <Divider />
            <Box padding={300} >
                <button
                    className={'collapse-button-container'}
                    onClick={() => handleToggle('mobile')}
                    aria-expanded={launcherID === 'mobile'}
                    aria-controls="basic-collapsible">
                    <LegacyStack alignment="center">
                        <LegacyStack.Item fill>
                            <div className='branding-toogle-container'>
                                <div className='branding-color-title'>
                                    <img
                                        src={MobileIcon}
                                        alt="color icon"
                                    />
                                </div>
                                <Text as="h3" variant="headingMd" fontWeight='medium'>
                                    Mobile
                                </Text>
                            </div>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                            {launcherID === 'mobile' ? <img src={ArrowUpIcon} alt="arrow down icon" /> : <img src={ArrowDownIcon} alt="arrow down icon" />}
                        </LegacyStack.Item>
                    </LegacyStack>
                </button>
            </Box>
            <Collapsible
                open={launcherID === 'mobile'}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                <Mobile formik={formik} />
            </Collapsible>


        </>
    );
};

export default Launcher;