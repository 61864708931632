import React, { useEffect, useState } from 'react';
import { Box, Divider, FormLayout, InlineError, Text, TextField, VerticalStack } from '@shopify/polaris';
import DropZoneFile from '../../../common-components/DropZoneFile';
import { useDispatch } from 'react-redux';
import { updateVisitorPanelData } from '../../../redux/slice/DataSlice';
import Input from '../../../common-components/Input';

function CommonSetting({ formik, brandingData }) {
    const panel_program_card_settings = formik.values?.panel_program_card_settings;
    const [visitorPanelData, setVisitorPanelData] = useState(panel_program_card_settings);
    const dispatch = useDispatch();

    useEffect(() => {
        setVisitorPanelData(formik.values?.panel_program_card_settings);
    }, [formik.values?.panel_program_card_settings]);

    useEffect(() => {
        if (formik?.values?.panel_program_card_settings?.earn_points_icon_type) {
            formik.setFieldValue('panel_program_card_settings.earn_points_icon_type', 1);
        }
    }, [formik.values?.panel_program_card_settings?.earn_points_icon_custom]);

    useEffect(() => {
        if (formik?.values?.panel_program_card_settings?.redeem_points_icon_type) {
            formik.setFieldValue('panel_program_card_settings.redeem_points_icon_type', 1);
        }
    }, [formik.values?.panel_program_card_settings?.redeem_points_icon_custom]);

    useEffect(() => {
        dispatch(updateVisitorPanelData(visitorPanelData));
    }, [visitorPanelData]);

    const handleImageUploadStatus = (value, point_icon_status) => {
        const iconType = point_icon_status ? 'panel_program_card_settings.earn_points_icon_type' : 'panel_program_card_settings.redeem_points_icon_type';
        if (value) {
            formik.setFieldValue(iconType, 1);
            formik.setFieldValue(point_icon_status ? 'panel_program_card_settings.earn_points_icon_custom' : 'panel_program_card_settings.redeem_points_icon_custom', value);
        } else {
            formik.setFieldValue(iconType, 0);
            formik.setFieldValue(point_icon_status ? 'panel_program_card_settings.earn_points_icon_custom' : 'panel_program_card_settings.redeem_points_icon_custom', undefined);
        }
    };   
    return (
        <>
            <Divider />
            <Box padding={300} >
                <VerticalStack gap={200}>
                    <Text as='h2' variant='headingMd'>Program-card setting</Text>
                    <Text as="h3" variant="headingMd">
                        Points
                    </Text>
                    <Input
                        label='Title'
                        placeholder='Loyalti Program'
                        type='text'
                        value={formik.values?.panel_program_card_settings?.points_title.trimStart()}
                        name='panel_program_card_settings.points_title'
                        handleChange={formik.setFieldValue}
                    />
                    <TextField
                        label='Description'
                        placeholder='Accrue {points} from a variety of actions and trade them for appealing rewards.'
                        autoComplete="off"
                        multiline={4}
                        type='text'
                        value={formik.values?.panel_program_card_settings?.points_desc}
                        name='points_desc'
                        onChange={(e) => formik.setFieldValue('panel_program_card_settings.points_desc', e)}
                    />
                    <DropZoneFile
                        fileLabel={'Earn points icon'}
                        fileAccept={'.png, .jpg, .jpeg'}
                        fileActionTitle={'Add icon'}
                        fileActionHint={'Size 90px by 90px'}
                        fileCustomIcon={formik.values?.panel_program_card_settings?.earn_points_icon_custom ? formik.values?.panel_program_card_settings?.earn_points_icon_custom : brandingData?.panel_program_card_settings?.earn_points_icon}
                        fileValue={formik.values?.panel_program_card_settings?.earn_points_icon_value ? formik.values?.panel_program_card_settings?.earn_points_icon_custom : formik.values?.panel_program_card_settings?.earn_points_icon}
                        fileIconType={brandingData?.panel_program_card_settings?.earn_points_icon_type || (formik.values?.panel_program_card_settings?.earn_points_icon_custom && formik.values?.panel_program_card_settings?.earn_points_icon_type) ? 1 : 0}
                        // handleImportValue={(value) => formik.setFieldValue('panel_program_card_settings.earn_points_icon_custom', value)} 
                        handleImportValue={(value) => handleImageUploadStatus(value, 1)}
                    />
                    {formik.errors?.panel_program_card_settings?.earn_points_icon_custom && <InlineError message={formik.errors?.panel_program_card_settings?.earn_points_icon_custom} />}
                    <DropZoneFile
                        fileLabel={'Redeem points icon'}
                        fileAccept={'.png, .jpg, .jpeg'}
                        fileActionTitle={'Add icon'}
                        fileActionHint={'Size 90px by 90px'}
                        fileCustomIcon={formik.values?.panel_program_card_settings?.redeem_points_icon_custom ? formik.values?.panel_program_card_settings?.redeem_points_icon_custom : brandingData?.panel_program_card_settings?.redeem_points_icon}
                        fileValue={formik.values?.panel_program_card_settings?.redeem_points_icon_value ? formik.values?.panel_program_card_settings?.redeem_points_icon_custom : formik.values?.panel_program_card_settings?.redeem_points_icon}
                        fileIconType={brandingData?.panel_program_card_settings?.redeem_points_icon_type || (formik.values?.panel_program_card_settings?.redeem_points_icon_custom && formik.values?.panel_program_card_settings?.redeem_points_icon_type) ? 1 : 0}
                        // handleImportValue={(value) => formik.setFieldValue('panel_program_card_settings.redeem_points_icon_custom', value)} 
                        handleImportValue={(value) => handleImageUploadStatus(value, 0)}

                    />
                    {formik.errors?.panel_program_card_settings?.redeem_points_icon_custom && <InlineError message={formik.errors?.panel_program_card_settings?.redeem_points_icon_custom} />}
                    {/* <DropZoneFile  fileLabel={'Referrals icon'} fileAccept={'.svg, .png'} fileActionTitle={'Add icon'} fileActionHint={'Size 90px by 90px'} handleImportValue={(value) => formik.setFieldValue('panel_referrals_details', value)} /> */}
                    {/* {customFile ? <div>
                                    {uploadedFile}
                                </div> : <Box paddingBlockStart={100}><DropZone allowMultiple={false} onDrop={handleUpload} type='file' accept='.csv'>
                                    <DropZone.FileUpload actionTitle='Add icon' actionHint='or drop files to upload'/>
                                </DropZone></Box>} */}
                </VerticalStack>
            </Box>
        </>
    );
}

export default CommonSetting;