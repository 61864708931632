import React, { useMemo } from 'react';
import { NavigationMenu, Provider, RoutePropagator } from '@shopify/app-bridge-react';
import { useLocation, Outlet } from 'react-router-dom';
import { config_variable } from '../helper/config';
import CommonRouter from '../helper/commonRoute';
const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));
const PointEditPage = React.lazy(() => import('../pages/program/PointEditPage'));
const RedeemPointEditPage = React.lazy(() => import('../pages/program/RedeemPointEditPage'));
const Nudges = React.lazy(() => import('../pages/nudge/Nudges'));
const NudgeEdit = React.lazy(() => import('../pages/nudge/NudgeEdit'));
const ReferralEdit = React.lazy(() => import('../pages/program/ReferralEdit'));
const Activity = React.lazy(() => import('../pages/activity/Activity'));
const ActivityDetail = React.lazy(() => import('../pages/activity/ActivityDetail'));
const CustomerList = React.lazy(() => import('../pages/customer/CustomerList'));
const CustomerDetails = React.lazy(() => import('../pages/customer/CustomerDetails'));
import { useDispatch, useSelector } from 'react-redux';
import { generateToken } from '../redux/slice/DataSlice';
import Branding from '../pages/branding/Branding';
import CustomerBulkEdit from '../pages/customer/CustomerBulkEdit';
const Settings = React.lazy(() => import('../pages/settings/Settings'));

export function DefaultLayout() {
    console.log('------------------DefaultLayout----------------');
    const location = useLocation();
    if (!config_variable.config.host) {
        config_variable.config.host = location?.state?.config?.host ? location?.state?.config.host : config_variable.config.host;
        config_variable.shop_name = location?.state?.shop_name ? location?.state?.shop_name : config_variable.shop_name;
    }
    const dispatch = useDispatch();
    useMemo(async () => {
        await dispatch(generateToken()).unwrap();
    }, []);

    const status = useSelector(state => state?.data?.status);
    const shop_info = useSelector((state) => state.data.store_data?.shop_data);
    const onboarding_status = shop_info?.onboarding_steps;

    return (
        <>
            <div>
                <>
                    {status && process.env?.MODE !== 'local' && onboarding_status === 4 ? <Provider config={config_variable.config}>
                        <NavigationMenu
                            navigationLinks={[
                                {
                                    label: 'Program',
                                    destination: CommonRouter.program
                                },
                                {
                                    label: 'Customers',
                                    destination: CommonRouter.customers
                                },
                                {
                                    label: 'Branding',
                                    destination: CommonRouter.branding
                                },
                                // {
                                //     label: 'Nudges',
                                //     destination: CommonRouter.nudges
                                // },
                                // {
                                //     label: 'Activity',
                                //     destination: CommonRouter.activity
                                // },
                                {
                                    label: 'Settings',
                                    destination: CommonRouter.settings
                                }
                            ]}
                            matcher={(link, location) => link.destination === location.pathname}
                        />
                        <RoutePropagator location={location} />
                        <Outlet />
                    </Provider> :  status && <Outlet />
                    }
                </>
            </div>
        </>
    );
}

export const routes = [
    { path: CommonRouter.program, component: <Dashboard /> },
    { path: `${CommonRouter.point}/:id`, component: <PointEditPage /> },
    { path: `${CommonRouter.redeem}/:id`, component: <RedeemPointEditPage /> },
    { path: CommonRouter.referral, component: <ReferralEdit /> },
    { path: CommonRouter.nudges, component: <Nudges /> },
    { path: `${CommonRouter.nudges}/:id`, component: <NudgeEdit /> },
    { path: CommonRouter.activity, component: <Activity /> },
    { path: `${CommonRouter.activity}/:id`, component: <ActivityDetail /> },
    { path: CommonRouter.customers, component: <CustomerList /> },
    { path: `${CommonRouter.customers}/:id`, component: <CustomerDetails /> },
    { path: `${CommonRouter.customers}/bulk`, component: <CustomerBulkEdit /> },
    { path: CommonRouter.branding, component: <Branding /> },
    { path: `${CommonRouter.settings}`, component: <Settings /> }
];
